// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button,
.btn {
    padding: 8px 20px;
    font-weight: bold;
    border-radius: 0;
    box-shadow: none !important;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

.btn-primary {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
    &:hover {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: #fff;
    }
    &:focus {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: #fff;
    }
    &:active,
    &:active:focus,
    &:active:hover {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: #fff;
    }
}

.btn-primary.disabled, 
.btn-primary:disabled {
    background-color:  $primary-color;
    border-color:  $primary-color;
}

.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
    background-color:  $primary-color;
    border-color:  $primary-color;
}

.btn-outline-dark {
    border-color: #000;
    &:hover {
        background-color: #000;
        color: #fff;
    }
}

.btn-dark {
    background-color: #000;
}

// scroll-top-btn
.scroll-top-btn {
    position: fixed;
    right: 5px;
    bottom: 5px;
    @include translate(0, 50px);
    z-index: 999;
    width: auto;
    background-color: #000;
    color: #fff;
    @include transition(.35s);
    padding: .375rem .75rem;
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }
    .fa {
        font-size: 14px;
    }
    &.reveal-btn {
        @include translate(0, 0);
    }
    @media(min-width: 575px) {
        bottom: 10px;
        right: 20px;
    }
}