@charset 'UTF-8';

// 1. Configuration
@import
  'abstracts/variables',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendor-extensions/bootstrap',
  'vendor-extensions/slick';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/spacing',
  'base/utils';

// 4. Components
@import
  'components/buttons',
  'components/forms';

// 5. Layout-related sections
@import
  'layout/header',
  'layout/footer';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/innerpage';
