.responsive-fs-video {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    .fs-video-width {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -9999px;
        right: -9999px;
        margin: auto;
    }
    /* set video aspect ratio and vertically center */
    .fs-video-aspect {
        position: absolute;
        width: 100%;
        height: 0;
        top: -9999px;
        bottom: -9999px;
        margin: auto;
        padding-bottom: 56.25%;
        /* 16:9 ratio */
        overflow: hidden;
    }
    .fs-video-make-height {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .fs-video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 0 none;
        pointer-events: none;
    }
    .video-poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-size: cover;
        background-position: center;
        @include transition(.35s);
        opacity: 1;
    }
}

.split-section {
    padding-top: 30px;
    padding-bottom: 30px;
    .img-section {
        margin-bottom: 40px;
        img {
            max-width: 100%;
        }
    }
    @media(min-width: 992px) {
        padding-top: 0;
        padding-bottom: 0;
        .img-section {
            height: 600px;
            margin-bottom: 0;
            img {
                @include object-fit(cover);
            }
        }
        .container-fluid {
            padding: 0;
            .row {
                margin: 0;
                > div[class*="col-"] {
                    padding: 0;
                }
            }
        }
    }
}

.partners-grid {
    .brand-card {
        padding: 15px;
        margin-bottom: 30px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        img {
            height: 50px;
            max-width: 280px;
            width: 100%;
            object-fit: contain;
        }
    }
    @media(min-width: 576px) {
        .brand-card {
            height: 200px;
            img {
                height: 150px;
            }
        }
    }
    @media(min-width: 992px) {
        .brand-card {
            height: 300px;
        }
    }
}

.promo-head-title {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 50px;
    .promo-ribbon {
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        -ms-flex-align: center;
        justify-content: center;
        -ms-flex-pack: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 130px;
        height: 135px;
        text-align: center;
        margin-right: 25px;
    }
    .price-before {
        position: relative;
        display: table;
        margin: auto;
        padding: 0 4px;
        &:before {
            position: absolute;
            left: 0;
            bottom: 4px;
            width: 100%;
            height: 1px;
            background-color: #414141;
            content: "";
            @include rotate(-15deg);
            transform-origin: left bottom;
            -webkit-transform-origin: left bottom;
        }
    }
    .price {
        font-weight: 700;
    }
    .text-title {
        margin: 15px 0;
        font-size: 32px;
    }
    @media(min-width: 576px) {
        .text-title {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
    }
    @media(min-width: 992px) {
        .promo-head-title {
            margin-top: 50px;
        }
    }
    @media(min-width: 1200px) {
        .text-title {
            font-size: 36px;
        }
    }
}

.banner-video {
    position: relative;
    height: 310px;
    @media(min-width: 768px) {
        height: 570px;
    }
    @media(min-width: 992px) {
        margin-top: -84px;
    }
    @media(min-width: 1440px) {
        margin-top: -90px;
    }
}

.map-section {
    iframe {
        width: 100%;
        height: 400px;
    }
    @media(min-width: 576px) {
        iframe {
            height: 500px;
        }
    }
}

.contact-section {
    .contact-item {
        margin-bottom: 20px;
        flex-wrap: wrap;
        -ms-flex-align: wrap;
        i {
            margin-top: 2px;
            font-size: 18px;
        }
    }
}

