// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
    padding: 20px 0;
    text-align: center;
    font-size: 10px;
    background-color: #000;
    color: #D8D8D8;
    @media(min-width: 768px) {
        font-size: 12px;
    }
}