// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.navbar-wrapper {
    height: 82px;
    @media(min-width: 576px) {
        height: 55px;
    }
    @media(min-width: 992px) {
        height: 84px;
    }
    @media(min-width: 1440px) {
        height: 90px;
    }
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    @include transition(.35s);
    &.fixed-active {
        background-color: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }
    .container-fluid {
        position: relative;
    }
    .navbar-brand {
        margin: 0;
        padding: 10px 0;
        background-color: #fff;
        img {
            height: 30px;
        }
    }
    @media(min-width: 576px) {
        .navbar-brand {
            img {
                height: 35px;
            }
        }
    }
    @media(min-width: 992px) {
        height: 84px;
        background-color: transparent;
        border-bottom: 0;
        .container-fluid {
            height: 100%;
            -ms-flex-align: end;
            align-items: flex-end;
        }
        .navbar-brand {
            position: absolute;
            top: 0;
            left: 15px;
            padding: 22px 30px;
            img {
                height: 40px;
            }
        }
    }
    @media(min-width: 1200px) {
        padding-left: 100px;
        padding-right: 100px;
        height: 84px;
        .container-fluid {
            max-width: 1400px;
        }
        .navbar-brand {
            padding: 19.5px 40px;
            img {
                height: 45px;
            }
        }
    }
    @media(min-width: 1440px) {
        height: 90px;
        .navbar-brand {
            padding: 15px 40px;
            img {
                height: 60px;
            }
        }
    }
}

.navbar-top-links {
    width: 100%;
    justify-content: flex-end;
    -ms-flex-pack: end;
    margin-left: -15px;
    margin-right: -15px;
    background-color: $primary-color;
    -ms-flex-positive: 1;
    flex-grow: 1;
    .nav-item {
        min-width: 40px;
        background-color: #fff;
        text-align: center;
        & + .nav-item {
            border-left: 1px solid #ddd;
        }
        &.active {
            color: $primary-color;
        }
    }
    .nav-link {
        padding: 6px 10px;
        font-weight: 600;
        font-size: 12px;
    }
    .tel-link {
        font-weight: 700;
        i {
            @include rotate(105deg);
            color: #fff;
        }
    }
    @media(min-width: 576px) {
        position: absolute;
        top: 8px;
        right: 50px;
        width: auto;
        background-color: transparent;
        margin: 0;
        .nav-item {
            min-width: 50px;
        }
        .nav-link {
            padding: 11px 15px;
        }
    }
    @media(min-width: 992px) {
        right: 15px;
        top: 0;
    }
    @media(min-width: 1440px) {
        .nav-link {
            font-size: 14px;
        }
    }
}


#main-navbar {
    .navbar-nav {
        margin-bottom: 10px;
        .nav-item {
            &.active,
            &.show {
                .nav-link {
                    color: $primary-color;
                }
            }
        }
        .nav-link {
            font-weight: 600;
            font-size: 14px;
            &:hover {
                background-color: #000;
                color: $primary-color;
            }
        }
    }
    @media(min-width: 992px) {
        background-color: #414141;
        .navbar-nav {
            margin-bottom: 0;
            margin-left: auto;
            .nav-item {
                &.active,
                &.show .nav-link {
                    background-color: #000;
                    cursor: default;
                }
            }
            .nav-link {
                padding: 13px 15px;
                font-size: 12px;
                color: #fff;
            }
            .nav-item.dropdown {
                &:hover {
                    .nav-link {
                        background-color: #000;
                        color: $primary-color;
                    }
                    .dropdown-menu {
                        @include translate(0, 0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .dropdown-menu {
                position: fixed;
                top: 90px;
                left: 0;
                display: block;
                padding: 0;
                margin: 0;
                width: 100%;
                border-radius: 0;
                border: 0;
                background-color: #E5E5E5;
                opacity: 0;
                visibility: hidden;
                @include translate(0, 5px);
                @include transition(.15s);
                &.show {
                    @include translate(0, 0);
                    opacity: 1;
                    visibility: visible;
                }
                > .container {
                    display: flex;
                    display: -ms-flexbox;
                    align-items: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -ms-flex-pack: center;
                }
                .dropdown-item {
                    padding: 10px 30px;
                    width: auto;
                }
            }
        }
    }
    @media(min-width: 1200px) {
        .navbar-nav {
            .nav-link {
                padding: 13px 16px;
            }
        }
    }
    @media(min-width: 1440px) {
        .navbar-nav {
            .nav-link {
                padding: 13px 22px;
                font-size: 14px;
            }
        }
    }
}