// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.main-banner-section {
    @media(min-width: 992px) {
        margin-top: -84px;
    }
    @media(min-width: 1440px) {
        margin-top: -90px;
    }
}

#main-slider {
    height: 400px;
    .content-container {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        -ms-flex-align: center;
        justify-content: center;
        -ms-flex-pack: center;
    }   
    .slide-content {
        color: #fff;
        text-align: center;
        .slide-title {
            font-weight: bold;
            font-size: 36px;
            line-height: 1.2;
        }
        .slide-lead {
            font-weight: 300;
            font-size: 24px;
        }
    }
    .bg-img-fill {
        &:before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            content: "";
        }
    }
    @media(min-width: 768px) {
        height: 570px;
        .slide-title {
            font-size: 42px;
        }
        .slide-lead {
            font-size: 30px;
        }
    }
}

.content-section-row {
    padding-top: 70px;
    padding-bottom: 70px;
}

.cta-section {
    position: relative;
    min-height: 400px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center;
    .bg-img-fill {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            content: "";
        }
        img {
            @include object-fit(cover);
        }
    }
    .container {
        position: relative;
        height: 100%;
        text-align: center;
        color: #fff;
    }
}

.footer-card {
    margin-bottom: 30px;
    border: 1px solid #707070;
    padding: 40px 15px 40px;
    text-align: center;
    background-color: #fff;
    .card-icon {
        margin-bottom: 40px;
        height: 80px;
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        -ms-flex-align: center;
        justify-content: center;
        -ms-flex-pack: center;
    }
    @media(min-width: 992px) {
        margin-bottom: 0;
    }
}

.collage-2 {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-direction: column;
    -ms-flex-direction: column;
    margin-bottom: -10%;
    .img-lg-wrap {
        width: 80%;
    }
    .img-sm-wrap {
        margin-left: auto;
        @include translate(0, -50%);
        width: 50%;
    }
    .img-container {
        img {
            @include object-fit(cover);
        }
    }
    @media(min-width: 992px) {
        padding-right: 50px;
        .img-lg-wrap {
            .img-container {
                height: 350px;
            }
        }
        .img-sm-wrap {
            margin-right: -15px;
            .img-container {
                height: 200px;
            }
        }
    }
}

.collage-3 {
    .img-lg-wrap {
        margin-bottom: 30px;
        
    }
    .img-container {
        img {
            height: auto;
            max-width: 100%;
        }
    }
    @media(min-width: 576px) {
        .img-lg-wrap {
            margin-bottom: 20px;
            .img-container {
                height: 350px;
            }
        }
        .img-md-wrap {
            .img-container {
                height: 265px;
            }
        }
        .img-sm-wrap {
            .img-container {
                height: 200px;
            }
        }
        .img-container {
            img {
                @include object-fit(cover);
            }
        }
    }
}