// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
body {
    font-family: 'Raleway', sans-serif;
    color: #414141;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

.btn,
a {
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}

hr {
    border-color: #414141;
}

input[type="email"],
input[type="password"],
input[type="text"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-focus-ring-color: none;
    outline: none;
}

textarea {
    resize: none;
}

iframe {
    display: block;
}