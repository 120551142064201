.text-primary {
    color: $primary-color !important;
}

.bg-primary {
    background-color: $primary-color !important;
}

.text-title {
    margin-bottom: 30px;
    font-weight: bold;
    color: #414141;
    line-height: 1.2;
}

.object-fit-cover {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover';
    }
}

.bg-gray {
    background-color: #E5E5E5;
}

.bg-cream {
    background-color: #F5F5F5;
}